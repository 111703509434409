import {
    doc,
    getDoc
  } from "firebase/firestore";
const { database } = require("./firebase");

export async function getLastUpdatedTime() {
    const getMetaData = doc(database, "metadata", "events");
    const querySnapshot = await getDoc(getMetaData);
    return querySnapshot.data().lastUpdatedAt;
}