import {
  onIdTokenChanged,
  RecaptchaVerifier,
  signOut,
  updateProfile,
  deleteUser,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithEmailAndPassword
} from "firebase/auth";
import { useEffect } from "react";
import { auth } from "./firebase";

//invisible recaptcha verifier
export function reCaptchaVerifier() {
  if (window.Cypress || process.env.REACT_APP_STAGING === "local") {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  return new RecaptchaVerifier(
    "login-button",
    {
      size: "invisible"
    },
    auth
  );
}

export async function login(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

export function getCurrentUser() {
  return auth.currentUser;
}

export async function otpValidate(loginOtp, verificationId) {
  const authCredentials = PhoneAuthProvider.credential(
    verificationId,
    loginOtp
  );
  return authCredentials;
}

export async function signInWithAuthCredentials(authCredentials) {
  const response = await signInWithCredential(auth, authCredentials);
  return response;
}

//SET USER DISPLAY NAME
export async function setDisplayName(clinicName) {
  await updateProfile(auth.currentUser, {
    displayName: clinicName
  });
}

//signup using auth credentials
export async function signUpWithAuthCredentials(authCredentials, name) {
  const response = await signInWithCredential(auth, authCredentials);
  if (response.user.displayName === null) {
    const displayName = {
      clinicStaff: name,
      doctor: null,
      patient: null
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName)
    });
  } else {
    let otherDisplayNames;
    try {
      otherDisplayNames = JSON.parse(response.user.displayName);
    } catch (error) {
      otherDisplayNames = {};
    }
    const displayName = {
      ...otherDisplayNames,
      clinicStaff: name
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName)
    });
  }
  return response;
}

//auth subscriber
export function useAuthSubscriber(onUserStateChange) {
  useEffect(() => {
    onIdTokenChanged(auth, async (user) => {
      onUserStateChange(user);
    });
    // eslint-disable-next-line
  }, []);
}

//logout subscriber
export const logout = async () => {
  await signOut(auth);
};

export async function deleteUserAccount() {
  await deleteUser(auth.currentUser);
}
