export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^[6-9]\d{9}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
};

export const defaultFields = [
  {
    type: "string",
    name: "blood group",
    required: true
  },
  {
    type: "string",
    name: "gender",
    required: true
  },
  {
    type: "string",
    name: "allergies",
    required: true
  },
  {
    type: "timestamp",
    name: "date of birth",
    required: true
  },
  {
    type: "float",
    name: "heart rate",
    required: true
  },
  {
    type: "float",
    name: "blood oxygen",
    required: true
  },
  {
    type: "float",
    name: "blood sugar",
    required: true
  },
  {
    type: "float",
    name: "body temperature",
    required: true
  },
  {
    type: "float",
    name: "blood pressure",
    required: true
  },
  {
    type: "float",
    name: "height",
    required: true
  },
  {
    type: "float",
    name: "weight",
    required: true
  }
];

export const dataTypeConversion = (dataType) => {
  if (dataType === "string") {
    return "Alphanumeric (All characters)";
  } else if (dataType === "Alphanumeric (All characters)") {
    return "string";
  } else if (dataType === "float") {
    return "Number (Digits)";
  } else if (dataType === "Number (Digits)") {
    return "float";
  } else if (dataType === "timestamp") {
    return "Date (Date)";
  } else if (dataType === "Date (Date)") {
    return "timestamp";
  } else {
    return "";
  }
};

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { weekday: "long" }) +
      " ";
    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject
        .toLocaleString("en-IN", { day: "numeric" })
        .toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject
        .toLocaleString("en-IN", {
          hour: "numeric" && "2-digit",
          minute: "numeric" && "2-digit",
          hour12: true
        })
        .toUpperCase() + " ";
    return date;
  }
}

export const validDocumentType = (data) => {
  let value;
  if (data) {
    switch (data) {
      case "OPConsultation":
        value = "OPConsultation";
        break;
      case "Prescription":
        value = "prescriptions";
        break;
      case "Prescriptions":
        value = "prescriptions";
        break;
      case "DischargeSummary":
        value = "admissions";
        break;
      case "DiagnosticReport":
        value = "reports";
        break;
      case "ImmunizationRecord":
        value = "ImmunizationRecord";
        break;
      case "HealthDocumentRecord":
        value = "HealthDocumentRecord";
        break;
      case "WellnessRecord":
        value = "WellnessRecord";
        break;
      default:
        break;
    }
  }
  return value;
};
