import { GoogleMapsLoader,
  GeoSearch,
  Marker,
  CustomMarker, } from 'react-instantsearch-dom-maps';
import algoliasearch from 'algoliasearch';
import "./App.css";
import { Configure, ClearRefinements, RefinementList, SearchBox, InstantSearch, connectHits } from 'react-instantsearch-dom';
import { useEffect, useRef, useState } from 'react';
// import filter from "./assets/filter.svg";
// import leftArrow from "./assets/leftArrow.svg";
import mapPin from "./assets/mapPin.svg";
import mapPinGreen from "./assets/mapPinGreen.svg";
import logoutIcon from "./assets/logout.svg";
import gccLogo from "./assets/gccLogo.jfif";
import scLogo from "./assets/scLogo.png";
import tnLogo from "./assets/tnLogo.png";
import providerLogo from "./assets/providerLogo.png";
import InputBox from "./front-end-global-components/components/InputBox/InputBox";
import {useAuthSubscriber, login, logout} from "./services/authentication"
import LogRocket from 'logrocket';
import { getLastUpdatedTime } from './services/database';

function App() {
  const [selectedHit, setSelectedHit] = useState();
  const [advancedFilters, setAdvancedFilters] = useState("");
  const [appliedAdvancedFilters, setAppliedAdvancedFilters] = useState("");
  // eslint-disable-next-line
  const [showFilters, setShowFilters] = useState(false);
  const [showMaps, setShowMaps] = useState(true);
  const [authData, setAuthData] = useState(null);
  const [projectionDate, setProjectionDate] = useState(+new Date());
  const [lastUpdatedAt, setLastUpdatedAt] = useState("");
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: ""
  });
  const [error, setError] = useState({
    status: true,
    message: ""
  });
  const filterInput = useRef();

  useAuthSubscriber((user) => setAuthData(user));

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
  );

  const onClearFilter = () => {
    setAppliedAdvancedFilters("");
    setAdvancedFilters("");
    const clearFiltersButton = document.getElementsByClassName("ais-ClearRefinements-button")[0];
    if (clearFiltersButton) {
      clearFiltersButton.disabled=true;
      clearFiltersButton.classList.add("ais-ClearRefinements-button--disabled");
    }
  }

  useEffect(() => {
    const clearFiltersButton = document.getElementsByClassName("ais-ClearRefinements-button")[0];
    if (clearFiltersButton) {
      if (appliedAdvancedFilters.length > 0) {
        clearFiltersButton.disabled=false;
        clearFiltersButton.classList.remove("ais-ClearRefinements-button--disabled");
      }
    }
  }, [appliedAdvancedFilters])

  useEffect(() => {
    if (process.env.REACT_APP_STAGING === "production" && window.location.hostname !== "localhost") {
      LogRocket.init('byepo/pct-csht6');
    }
  }, [])

  useEffect(() => {
    if (authData && authData.uid) {
      if (process.env.REACT_APP_STAGING === "production" && window.location.hostname !== "localhost") {
        LogRocket.identify(authData.uid, {
          ...authData.displayName ? {name: authData.displayName} : {},
          email: authData.email,
        });
      }
      getLastUpdatedTime().then(timestamp => {
        setLastUpdatedAt(timestamp);
      })
    }
  }, [authData])

  useEffect(() => {
    if (selectedHit) {
      setShowMaps(false);
    }
  }, [selectedHit])

  useEffect(() => {
    if (showMaps === false && selectedHit) {
      document.getElementById(selectedHit.objectID).scrollIntoView({
        block: "center",
        behavior: 'smooth'
      });
      setSelectedHit(null);
      document.getElementById(selectedHit.objectID).style.animationDelay="1s";
      document.getElementById(selectedHit.objectID).style.animation="shadows 1s";
      setTimeout(() => {
        document.getElementById(selectedHit.objectID).style.animationDelay=undefined;
        document.getElementById(selectedHit.objectID).style.animation=undefined;
      }, 1000)
    }
  }, [showMaps, selectedHit])

  // const transformFilterNames = (filterName) => {
  //   switch (filterName) {
  //     case "category": return "Category";
  //     case "changesProposed": return "Proposed Changes";
  //     case "condition": return "Current Condition";
  //     case "milestone": return "Milestone";
  //     case "quality": return "Current Quality";
  //     default: return "";
  //   }
  // }

	return (
    <>
      {
        authData && <div className="inherit-parent-height">
          <InstantSearch
            searchClient={searchClient}
            indexName="LOCATIONS"
          >
            <div className="flex-center-children-vertically height-64px background-color-primary padding-horizontal-1-rem" style={{justifyContent: "space-between"}}>
              <div className="flex-center-children-vertically">
                <div style={{height:"100%", color: "white"}}>
                  <p>Public Convenience Toilets</p>
                  <p style={{fontSize: "12px"}}>Zones 5, 6 and 9 (Marina)</p>
                </div>
              </div>
              <div style={{borderRadius: "3px", minWidth: "30%"}}>
                <SearchBox 
                  searchAsYouType={true} 
                />
              </div>
              <div className="flex-center-children-vertically">
                <img 
                  onClick={event => {
                    logout();
                  }}
                  style = {{
                    height: "18px",
                    width: "auto",
                    alignSelf: "center",
                    cursor: "pointer"
                  }}
                  alt="logout" 
                  src={logoutIcon} 
                />
              </div>
            </div>
            <div className="flex-center-children-vertically flex-center-children-horizontally height-48px padding-horizontal-1-rem">
              <div className='padding-horizontal-1-rem' onClick={() => setShowMaps(!showMaps)}>
                <div className="switch-button">
                  <input checked={!showMaps} className="switch-button-checkbox" type="checkbox"></input>
                  <label className="switch-button-label" htmlFor=""><span className="switch-button-label-span">Map</span></label>
                </div>
              </div>
            </div>
            <div className="parent-height-exclude-112px position-relative">
              <div className="flex-center-children-vertically inherit-parent-height position-relative">
                <div style={{width: "25%"}} className="width-25-percentage inherit-parent-height overflow-auto">
                  <div style={{height: "50%", overflow: "auto", backgroundColor: "white", position: "relative"}}>
                    <p style={{textAlign: "center", position: "sticky", top: "0", fontWeight: "bold"}} className="padding-vertical-half-rem filter-card">Summary</p>
                    <div className="filter-card">
                      <div className='flex-center-children-vertically'>
                        <p className="width-50-percentage padding-horizontal-1-rem">Status: </p>
                        <div>
                          <SummaryHits
                            projectionDate={projectionDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="filter-card">
                      <div className='flex-center-children-vertically'>
                        <p className="width-50-percentage padding-horizontal-1-rem">Zones: </p>
                        <div>
                          <ZonesSummary
                            projectionDate={projectionDate}
                          />
                        </div>
                      </div>
                    </div>
                    <p style={{position: "absolute", bottom: "0", width: "100%", marginBottom: "2px"}} className="filter-card padding-horizontal-1-rem">Last Updated: {new Date(lastUpdatedAt).toLocaleDateString("en-IN")}</p>
                  </div>
                  <div style={{height: "50%", overflow: "auto"}}>
                    <p style={{textAlign: "center", position: "sticky", top: "0", backgroundColor: "white", fontWeight: "bold"}} className="padding-vertical-half-rem filter-card">Filters</p>
                    <div className="filter-card padding-horizontal-1-rem">
                      <p style={{paddingBottom: "1rem"}} className="display-block">Date: {new Date(projectionDate).toLocaleDateString("en-IN")} </p>
                      <input 
                        type="range" 
                        min="1677609000000" 
                        max={`${+new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 3, 
                            new Date().getDate()
                        )}`} 
                        defaultValue={`${+new Date()}`}
                        className={"slider"}
                        onChange={event => setProjectionDate(+event.target.value)}
                      />
                    </div>
                    <div className="flex-center-children-vertically filter-card">
                      <p className="width-50-percentage padding-horizontal-1-rem">Category: </p>
                      <RefinementList
                        attribute="category"
                        transformItems={items => {
                          function convertLabel(label) {
                            switch (label) {
                              case "community": return "Community";
                              case "institutional": return "Institutional";
                              case "publicSpace": return "Public Space";
                              case "transitArea": return "Transit Area";
                              default: return "";
                            }
                          }
                          return items.map(item => ({
                            ...item,
                            label: convertLabel(item.label),
                          }))
                        }}
                      />
                    </div>
                    <div className="flex-center-children-vertically filter-card">
                      <p className="width-50-percentage padding-horizontal-1-rem">Proposed Changes: </p>
                      <RefinementList
                        attribute="changesProposed"
                        transformItems={items => {
                          function convertLabel(label) {
                            switch (label) {
                              case "minor": return "Minor";
                              case "major": return "Major";
                              case "new": return "New";
                              default: return "";
                            }
                          }
                          return items.map(item => ({
                            ...item,
                            label: convertLabel(item.label),
                          }))
                        }}
                      />
                    </div>
                    <div className="flex-center-children-vertically filter-card">
                      <p className="width-50-percentage padding-horizontal-1-rem">Current Condition: </p>
                      <RefinementList
                        attribute="condition"
                        transformItems={items => {
                          function convertLabel(label) {
                            switch (label) {
                              case "inUse": return "In Usage";
                              case "defunct": return "Defunct";
                              case "renew": return "Under Renewal";
                              case "demolished": return "Demolished";
                              default: return "";
                            }
                          }
                          return items.map(item => ({
                            ...item,
                            label: convertLabel(item.label),
                          }))
                        }}
                      />
                    </div>
                    <div className="flex-center-children-vertically filter-card">
                      <p className="width-50-percentage padding-horizontal-1-rem">Milestone: </p>
                      <RefinementList
                        attribute="milestone"
                        transformItems={items => {
                          function convertLabel(label) {
                            switch (label) {
                              case "1": return "Milestone 1";
                              case "2": return "Milestone 2";
                              case "3": return "Milestone 3";
                              case "4": return "Milestone 4";
                              default: return "";
                            }
                          }
                          return items.map(item => ({
                            ...item,
                            label: convertLabel(item.label),
                          }))
                        }}
                      />
                    </div>
                    <div className="flex-center-children-vertically filter-card">
                      <p className="width-50-percentage padding-horizontal-1-rem">Current Quality: </p>
                      <RefinementList
                        attribute="quality"
                        transformItems={items => {
                          function convertLabel(label) {
                            switch (label) {
                              case "good": return "Good";
                              case "bad": return "Bad";
                              case "renew": return "Under Renewal";
                              case "demolished": return "Demolished";
                              default: return "";
                            }
                          }
                          return items.map(item => ({
                            ...item,
                            label: convertLabel(item.label),
                          }))
                        }}
                      />
                    </div>
                    
                    <div
                    >
                      <form 
                        onSubmit={event => {
                          event.preventDefault();
                          setAppliedAdvancedFilters(advancedFilters);
                        }} 
                        className="flex-center-children-vertically position-relative padding-vertical-half-rem padding-horizontal-1-rem"
                      >
                        <p className="advanced-filters-icon"></p>
                        <input
                          onChange={event => setAdvancedFilters(event.target.value)}
                          value={advancedFilters}
                          ref={filterInput} name="filter" className="ais-SearchBox-input advanced-filters" type="search" placeholder="Advanced Filters" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" required="" maxLength="512"
                        />
                        {
                          (!!advancedFilters || !!appliedAdvancedFilters) && <button 
                            onClick={() => {
                              setAdvancedFilters("");
                              setAppliedAdvancedFilters("");
                            }}
                            type="button" title="Clear the search query." className={"ais-SearchBox-reset ais-advanced-filter-position"}
                          >
                            <svg className={"ais-SearchBox-resetIcon"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="10" height="10">
                              <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
                            </svg>
                          </button>
                        }
                        <button className="ais-RangeInput-submit" type="submit">Search</button>
                        <Configure
                          filters={appliedAdvancedFilters}
                        />
                      </form>
                    </div>
                    <div 
                      style={{position:"sticky", bottom:"0px"}}
                      onClick={event => {
                        onClearFilter();
                      }}
                      className="inherit-parent-width filter-card padding-vertical-half-rem padding-horizontal-1-rem"
                    >
                      <ClearRefinements  />
                    </div>
                  </div>
                  
                </div>
                <div style={{width: "75%"}} className="width-70-percentage inherit-parent-height">
                  {/* {
                    selectedHit && <div className="inherit-parent-height overflow-auto">
                      <p style={{position: "sticky", top:"0px"}} className="filter-card cursor-pointer" onClick={() => setSelectedHit(null)}>{ "< Back"}</p>
                      <p className="text-align-center padding-vertical-half-rem">Selected Facility Data</p>
                      <pre className="padding-vertical-half-rem padding-horizontal-1-rem">
                        {JSON.stringify(selectedHit, null, 2)}
                      </pre>
                    </div>
                  } */}
                  {
                    !showMaps &&
                      <div id="dataTable" className="inherit-parent-height overflow-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Serial No.</th>
                            <th>Zone</th>
                            <th>Ward</th>
                            <th>Location</th>
                            <th>Status</th>
                            <th>Milestone</th>
                            <th>Total Seats</th>
                          </tr>
                        </thead>
                        <tbody>
                          <CustomHits 
                            onHitClick={(hit) => {
                              setSelectedHit(hit);
                              setShowFilters(false);
                            }}
                            projectionDate={projectionDate}
                          />
                        </tbody>
                      </table>
                    </div>
                  }
                  {
                    !selectedHit && showMaps && <GoogleMapsLoader 
                      apiKey={process.env.REACT_APP_MAPS_API_KEY}
                      endpoint={`https://maps.googleapis.com/maps/api/js?v=weekly&callback=Function.prototype`}
                    >
                      {
                        google => (
                          <GeoSearch 
                            google={google}
                            initialZoom={13}
                            initialPosition={{
                              lat: 13.074018622906372,
                              lng: 80.25394342019891,
                            }}
                            enableRefine={false}
                          >
                            {
                              ({hits}) => 
                                <RenderMapMarkers 
                                  searchResults={ hits }
                                  projectionDate={projectionDate}
                                  onHitClick={(hit) => {
                                    setSelectedHit(hit);
                                    setShowFilters(false);
                                  }}
                                />
                            }
                          </GeoSearch>
                        )
                      }
                    </GoogleMapsLoader>
                  }
                </div>
              </div>
              {/* <div style={{justifyContent:"space-between"}} className="flex-center-children-vertically height-64px padding-horizontal-1-rem inherit-parent-width">
                <p>
                  Last Updated: {new Date(lastUpdatedAt).toLocaleDateString("en-IN")}
                </p>
                <p className="flex-center-children-vertically">
                  Status Summary: <SummaryHits
                    projectionDate={projectionDate}
                  />
                </p>
              </div> */}
            </div>
          </InstantSearch>
        </div>
      }
      {
        !authData && <div style={{justifyContent:"space-between"}} className="inherit-parent-height flex-center-children-vertically flex-direction-column">
          <div className="padding-vertical-half-rem inherit-parent-width" style={{marginTop: "7%", height:"20%", display: "flex", justifyContent: "space-around"}}>
            <img className="inherit-parent-height padding-horizontal-1-rem" src={gccLogo} alt="gccLogo"/>
            <img className="inherit-parent-height padding-horizontal-1-rem" src={scLogo} alt="gccLogo"/>
            <img className="inherit-parent-height padding-horizontal-1-rem" src={tnLogo} alt="providerLogo" />
          </div>
          <form
            className="flex-center-children-horizontally flex-direction-column"
            onChange={(event) => {
              setLoginFormData({
                ...loginFormData,
                [event.target.name]: event.target.value
              })
              if (error.message.length>0) {
                setError({
                  status: true,
                  message: ""
                });
              }
            }}
            onSubmit={async event => {
              event.preventDefault();
              try {
                await login(loginFormData.email, loginFormData.password);
              } catch (error) {
                setError({
                  status: false,
                  message: error.message.replace("Firebase: ", "")
                })
              }
            }}
          >
            <p className="text-align-center padding-vertical-half-rem">Please enter your credentials</p>
            <InputBox 
              label="Email ID"
              removeResponsive={true}
              type="email"
              required={true}
              name="email"
              error={error.message}
              value={loginFormData.email}
            />
            <InputBox 
              label="Password"
              removeResponsive={true}
              type="password"
              name="password"
              required={true}
              error={error.message}
              showError={true}
              value={loginFormData.password}
            />
            <div className="flex-center-children-horizontally padding-vertical-half-rem">
              <button className="ais-RangeInput-submit">Login</button>
            </div>
            {/* eslint-disable-next-line */}
            {/* <p className="padding-horizontal-1-rem padding-vertical-half-rem">Require an account? <a onClick={event => event.preventDefault()} href="">Click Here</a> to raise a request</p> */}
          </form>
          <div className="padding-vertical-half-rem" style={{height:"12%", textAlign:"center", marginBottom: "5%"}}>
            <img className="inherit-parent-height padding-horizontal-1-rem" src={providerLogo} alt="providerLogo" />
          </div>
        </div>
      }
    </>
  );
}

function transformProjectedStatus(status) {
  switch (status) {
    case "started": {
      return "Started";
    }
    case "completed": {
      return "Completed";
    }
    default: {
      return "N/A";
    }
  }
}

function Results ( props ) {
  return <>
    {
      props.searchResults.map(hit => {
        let projectedStatus = hit.currentStatus?.status ? hit.currentStatus?.status : "N/A";
        if (typeof hit.projectedStatuses === "object") {
          Object.entries(hit.projectedStatuses).forEach(([status,timestamp]) => {
            if ((hit.currentStatus?.updatedAt === undefined || timestamp > hit.currentStatus?.updatedAt) && timestamp <= props.projectionDate) {
              if (hit.projectedStatuses[projectedStatus] === undefined || hit.projectedStatuses[projectedStatus] < timestamp) {
                projectedStatus = status;
              }
            }
          });
        }
        return (
            <tr
            key={hit.objectID}
            id={hit.objectID}
            onClick={() => {
              props.onHitClick(hit);
            }} 
            style={{cursor: "pointer"}}
          >
            <td>{hit.serialNo}</td>
            <td>{hit.zone}</td>
            <td>{hit.ward}</td>
            <td>{hit.location}</td>
            <td>{transformProjectedStatus(projectedStatus)}</td>
            <td>{hit.milestone}</td>
            <td>{hit.seats.total}</td>
          </tr>
          
        )
      })
    }
  </>
}

function SummaryResults ( props ) {
  let statuses = {};
  props.searchResults.forEach(hit => {
    let projectedStatus = hit.currentStatus?.status ? hit.currentStatus?.status : "N/A";
    if (typeof hit.projectedStatuses === "object") {
      Object.entries(hit.projectedStatuses).forEach(([status,timestamp]) => {
        if ((hit.currentStatus?.updatedAt === undefined || timestamp > hit.currentStatus?.updatedAt) && timestamp <= props.projectionDate) {
          if (hit.projectedStatuses[projectedStatus] === undefined || hit.projectedStatuses[projectedStatus] < timestamp) {
            projectedStatus = status;
          }
        }
      });
    }
    statuses[projectedStatus] = statuses[projectedStatus] ? statuses[projectedStatus] + 1 : 1;
  });
  // return <>
  //   { 
  //     Object.entries(statuses).map(([status, count]) => <div key={status} className='chip margin-horizontal-half-rem'>{transformProjectedStatus(status)} {count}</div>)
  //   }
  // </>
  return <>
    { 
      Object.entries(statuses).map(([status, count]) => <div style={{fontSize: ".875rem"}} key={status} className='margin-horizontal-half-rem'>{transformProjectedStatus(status)}: <span className='ais-RefinementList-count'>{count}</span></div>)
    }
  </>
}

function ZonesRender ( props ) {
  let zones = {};
  props.searchResults.forEach(hit => {
    zones[hit.zone] = zones[hit.zone] ? zones[hit.zone] + 1 : 1;
  });
  // return <>
  //   { 
  //     Object.entries(statuses).map(([status, count]) => <div key={status} className='chip margin-horizontal-half-rem'>{transformProjectedStatus(status)} {count}</div>)
  //   }
  // </>
  return <>
    { 
      Object.entries(zones).map(([status, count]) => <div style={{fontSize: ".875rem"}} key={status} className='margin-horizontal-half-rem'>Zone {status}: <span className='ais-RefinementList-count'>{count}</span></div>)
    }
  </>
}

function RenderMapMarkers ( props ) {
  return <div>
    {
      props.searchResults.map(hit => {
        let projectedStatus = hit.currentStatus?.status ? hit.currentStatus?.status : "N/A";
        if (typeof hit.projectedStatuses === "object") {
          Object.entries(hit.projectedStatuses).forEach(([status,timestamp]) => {
            if ((hit.currentStatus?.updatedAt === undefined || timestamp > hit.currentStatus?.updatedAt) && timestamp <= props.projectionDate) {
              if (hit.projectedStatuses[projectedStatus] === undefined || hit.projectedStatuses[projectedStatus] < timestamp) {
                projectedStatus = status;
              }
            }
          });
        }
        switch (projectedStatus) {
          case "started": {
            return <Marker
              key={hit.objectID}
              hit={hit}
              onClick={({ event, marker }) => {
                props.onHitClick(hit);
              }}
            >
            </Marker>
          }
          case "completed": {
            return <CustomMarker
              key={hit.objectID}
              hit={hit}
              anchor={{ x: 0, y: -24 }}
              className={"height-64px cursor-pointer"}
              onClick={({ event, marker }) => {
                props.onHitClick(hit);
              }}
            >
              <img 
                alt="pin-started" 
                src={mapPinGreen} 
                className="custom-map-pin" 
              />
            </CustomMarker>
          }
        
          default:{
            return <CustomMarker
              key={hit.objectID}
              hit={hit}
              anchor={{ x: 0, y: -24 }}
              className={"height-64px cursor-pointer"}
              onClick={({ event, marker }) => {
                props.onHitClick(hit);
              }}
            >
              <img 
                alt="pin-started" 
                src={mapPin} 
                className="custom-map-pin" 
              />
            </CustomMarker>
          }
        }
      })
    }
  </div>
}

// function AppliedFilters ( props ) {
//   return <>
//     { 
//       props.items.map(filter => <div className='chip margin-horizontal-half-rem'>{filter.attribute}</div>)
//     }
//   </>
// }

const CustomHits = connectHits((props) => <Results
  searchResults={props.hits}
  onHitClick={props.onHitClick}
  projectionDate={props.projectionDate}
/>)

const SummaryHits = connectHits((props) => <SummaryResults
  searchResults={props.hits}
  projectionDate={props.projectionDate}
/>)

const ZonesSummary = connectHits((props) => <ZonesRender
  searchResults={props.hits}
  projectionDate={props.projectionDate}
/>)

// const CustomAppliedFilters = connectCurrentRefinements(AppliedFilters);

export default App;
